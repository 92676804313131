<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="id" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.id" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaInfo" label="搜索(编码/名称/地址)">
						<a-input v-model:value="formState.cinemaInfo" placeholder="请输入编码/名称/地址"></a-input>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['ciname_basis_info_add']" type="primary" @click="onAdd">新增影院</a-button>
						<a-button v-permission="['ciname_basis_info_sync']" type="primary" @click="onSync" style="margin-left: 20px;">更新影院</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1100 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'info'">
							<div>
								影院名称：{{ record.name }}
							</div>
							<div>
								影院地址： {{ record.address }}
							</div>
						</template>
						<template v-if="column.key === 'isVisionTips'">
							{{ record.isVisionTips ? '是' : '否' }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'onLine'">
							<span style="color: green;" v-if="record.onLine">在线</span>
							<span style="color: red;" v-else>不在线</span>
						</template>
						<template v-if="column.key === 'dataOnLine'">
							<span style="color: green;" v-if="record.dataOnLine">在线</span>
							<span style="color: red;" v-else>不在线</span>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSee(record)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div>
										<div v-permission="['ciname_basis_info_edit']" @click="onEdit(record)">
											<a-menu-item>
												修改
											</a-menu-item>
										</div>
										<div @click="onView(record, 1)">
											<a-menu-item>
												微信访问影城二维码
											</a-menu-item>
										</div>
<!--										<div @click="onView(record, 2)">
											<a-menu-item>
												抖音访问影城二维码
											</a-menu-item>
										</div>-->
										<div @click="onPagepath(record)">
											<a-menu-item>查看微信公众号访问路径</a-menu-item>
										</div>
										<div v-permission="['ciname_basis_info_sync_hall']" @click="onSyncHall(record)">
											<a-menu-item>
												同步影厅
											</a-menu-item>
										</div>
										<div v-permission="['ciname_basis_info_pay']" @click="getPayQR(record)">
											<a-menu-item>影院付款二维码</a-menu-item>
										</div>
										<div v-permission="['ciname_basis_info_sync_schedules']" @click="onPullSchedules(record)">
											<a-menu-item>
												拉取排期
											</a-menu-item>
										</div>
										<div v-permission="['ciname_basis_info_sync_pre']" @click="onPreSalePull(record)">
											<a-menu-item>
												拉取预售排期
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		<a-modal v-model:visible="visible" :title="isPay ? '影城支付二维码' : '访问影城'" width="500px">
			<template #footer>
				<a-button type="primary" @click="visible = false;">关闭</a-button>
			</template>
			<div>影院名称：{{ title || '-' }}</div>
			<div style="margin: 10px 0;" v-if="!isPay">
				<span>公众号访问路径：<span id="path">pages/enterIndex/index?cId={{ id }}&sId={{ storeId }}</span></span>
				<a-button type="link" size="small" @click="onCopy">复制路径</a-button>
			</div>
			<div v-if="imgUrl" style="text-align: center;">
				<a-image style="width: 300px;" :src="imgUrl"></a-image>
			</div>
		</a-modal>
	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getShareQR } from '@/service/modules/advert.js';
	import { getOrganizationList, getCinemaList, getCinemaSync, getCinemaHallSync,  pullSchedules } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				loading: false,
				isEdit: false,
				isSee: false,
				id: 0,
				storeId: 0,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					// organizationId: 0,
					id: 0,
				},
				searchData: {},
				showModal: false,
				list: [],
				columns: [{
					title: '影院国家编码',
					dataIndex: 'code',
					width: 180
				}, {
					title: '影院信息',
					key: 'info'
				}, {
					title: '联系人',
					dataIndex:'contactPeople',
					width: 200
				},
				// {
				// 	title: '情侣座释放模式'
				// },
				// {
				// 	title: '视觉提醒',
				// 	key: 'isVisionTips'
				// },
				// {
				// 	title: '会员卡购票验证'
				// },
				{
					title: '状态',
					key: 'isDisabled',
					width: 100
				},
				{
					title: '是否在线',
					key: 'onLine',
					width: 150
				}, {
					title: '数据库是否在线',
					key: 'dataOnLine',
					width: 140
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				visible: false,
				isPay: false,
				title: '',
				imgUrl: ''
			}
		},
		created() {
			this.getOrganizationList();
			// this.getAllCinemaList();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.id = this.searchData.id ? this.searchData.id : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCinemaList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.getData();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.id = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async onSync() {
				this.loading = true;
				try {
					let ret = await getCinemaSync({});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('更新成功');
						this.getData();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onSyncHall(item) {
				this.loading = true;
				try {
					let ret = await getCinemaHallSync({
						id: item.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('更新成功');
						this.getData();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onPullSchedules(item) {
				this.loading = true;
				try {
					let ret = await pullSchedules({
						locationCd: item.cinemaLinkId,
					});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('拉取排期成功');
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onPagepath(record) {
				this.id = record.id;
				this.storeId = record.storeId;
				this.title = record.name || '';
				this.imgUrl = '';
				this.isPay = false;
				this.visible = true;
			},
			onCopy(){
				const range = document.createRange();
				range.selectNode(document.querySelector("#path"));
				const selection = window.getSelection();
				if (selection.rangeCount > 0) selection.removeAllRanges();
				selection.addRange(range);
				document.execCommand('Copy');
				this.$message.success('复制成功');
			},
			onPreSalePull(item) {
				this.$confirm({
					title: '提示',
					content: `请确认是否拉取未来4-${ item.presaleDay }天的预售排期？`,
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await pullSchedules({
								locationCd: item.cinemaLinkId,
								type: 1
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('拉取预售排期完成');
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onAdd() {
				this.isEdit = false;
				this.id = 0;
				this.isSee = false;
				this.showModal = true;
			},
			onEdit(item) {
				this.isEdit = true,
				this.id = item.id;
				this.isSee = false;
				this.showModal = true;
			},
			onSee(item) {
				this.isEdit = true,
				this.id = item.id;
				this.isSee = true;
				this.showModal = true;
			},
			async onView(item, source) {
				this.loading = true;
				try {
					let ret = await getShareQR({
						page: 'pages/enterIndex/index',
						scene: `path=1&cId=${item.id}`,
						source
					});
					this.loading = false;
					if (ret.code === 200) {
						this.id = item.id;
						this.storeId = item.storeId;
						this.title = item.name;
						this.imgUrl = ret.data.shareQR;
						this.isPay = false;
						this.visible = true;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getPayQR(item) {
				this.loading = true;
				try {
					let ret = await getShareQR({
						page: 'pages/enterIndex/index',
						scene: `path=5&id=${item.id}`,
						source: 1
					});
					this.loading = false;
					if (ret.code === 200) {
						this.title = item.name;
						this.imgUrl = ret.data.shareQR;
						this.isPay = true;
						this.visible = true;
					}
				} catch(e) {
					this.loading = false;
				}
			}
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
